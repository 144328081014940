<template>
  <div class="chart-bg pt-3">
    <b-container>
      <b-row class="chart-gap" v-if="!loading">
        <b-col md="12">
          <RFMChartPanel
            id="bar-chart-recency"
            title="Recency"
            :chartRange="chartRange.recency"
            :average="returnAvg('Recency') | numeral('0,0.00')"
            titleLeft="Number of Persons"
            titleBottom="Number of Day"
            @export="exportFile(1)"
          />
        </b-col>
        <b-col md="12">
          <RFMChartPanel
            id="bar-chart-frequency"
            title="Frequency"
            :average="returnAvg('Frequency') | numeral('0,0.00')"
            :chartRange="chartRange.frequency"
            titleLeft="Number of Persons"
            titleBottom="Time(s)"
            @export="exportFile(2)"
          />
        </b-col>
        <b-col md="12">
          <RFMChartPanel
            id="bar-chart-monetary"
            title="Monetary"
            :average="returnAvg('Monetary') | numeral('0,0.00')"
            :chartRange="chartRange.monetary"
            titleLeft="Number of Persons"
            titleBottom="Baht"
            @export="exportFile(3)"
          />
        </b-col>
        <b-col md="12">
          <RFMChartPanel
            id="bar-chart-repeatPurchase"
            title="Return Purchase"
            :average="returnAvg('Repurchase') | numeral('0,0.00')"
            :chartRange="chartRange.repeat_purchase"
            titleLeft="Number of Persons"
            titleBottom="Day(s)"
            @export="exportFile(4)"
          />
        </b-col>
        <b-col md="12">
          <RFMChartPanel
            id="bar-chart-totalspend"
            title="Total Spend"
            :average="returnAvg('TotalSpend') | numeral('0,0.00')"
            :chartRange="chartRange.total_spend"
            titleLeft="Number of Persons"
            titleBottom="Day(s)"
            @export="exportFile(4)"
          /> </b-col></b-row
    ></b-container>
  </div>
</template>
<script>
import RFMChartPanel from "@/components/dashboard/RFM/RFMChartPanel.vue";
export default {
  name: "RFMPanel",
  components: { RFMChartPanel },
  props: {
    RFMData: { required: true, type: Array },
  },
  data() {
    return {
      show: false,
      chartData: [],
      chartRange: [],
      loading: true,
      avg: {
        recency: 0,
        frequency: 0,
        monetary: 0,
        repeat_purchase: 0,
        total_spend: 0,
      },
    };
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    async getLabel() {
      try {
        var chartRange = {
          frequency: {
            range: 0,
            label: [],
            count: {
              min: [],
              avg_min: [],
              avg: [],
              avg_max: [],
              max: [],
              sum: [],
            },
            $percent: [],
            accumulate: {
              percent: [],
              num: [],
            },
            data: [],
          },
          monetary: {
            range: 0,
            label: [],
            count: {
              min: [],
              avg_min: [],
              avg: [],
              avg_max: [],
              max: [],
              sum: [],
            },
            $percent: [],
            accumulate: {
              percent: [],
              num: [],
            },
            data: [],
          },
          recency: {
            range: 0,
            label: [],
            count: {
              min: [],
              avg_min: [],
              avg: [],
              avg_max: [],
              max: [],
              sum: [],
            },
            $percent: [],
            accumulate: {
              percent: [],
              num: [],
            },
            data: [],
          },
          repeat_purchase: {
            range: 0,
            label: [],
            count: {
              min: [],
              avg_min: [],
              avg: [],
              avg_max: [],
              max: [],
              sum: [],
            },
            $percent: [],
            accumulate: {
              percent: [],
              num: [],
            },
            data: [],
          },
          total_spend: {
            range: 0,
            label: [],
            count: {
              min: [],
              avg_min: [],
              avg: [],
              avg_max: [],
              max: [],
              sum: [],
            },
            $percent: [],
            accumulate: {
              percent: [],
              num: [],
            },
            data: [],
          },
        };

        this.chartData = this.RFMData;

        const getArrayData = (key) => {
          if (this.chartData.find((o) => o.name_dashboard == key))
            return [
              ...this.chartData.find((o) => o.name_dashboard == key)
                .dashBoardRFMGraphScales,
            ];
          return [];
        };
        let arrayRecency = getArrayData("Recency");
        let arrayFrequency = getArrayData("Frequency");
        let arrayMonetary = getArrayData("Monetary");
        let arrayRepeatPurchase = getArrayData("Repurchase");
        let arrayTotalSpend = getArrayData("TotalSpend");

        chartRange.recency.label = arrayRecency.map((el) => {
          if (el.upper_bound) {
            return `${this.addCommas(el.lower_bound)} - ${this.addCommas(
              el.upper_bound
            )}`;
          } else {
            return `${this.addCommas(el.lower_bound)} and more`;
          }
        });
        chartRange.frequency.label = arrayFrequency.map((el) => {
          if (el.upper_bound) {
            return `${this.addCommas(el.lower_bound)} - ${this.addCommas(
              el.upper_bound
            )}`;
          } else {
            return `${this.addCommas(el.lower_bound)} and more`;
          }
        });
        chartRange.monetary.label = arrayMonetary.map((el) => {
          if (el.upper_bound) {
            return `${this.addCommas(el.lower_bound)} - ${this.addCommas(
              el.upper_bound
            )}`;
          } else {
            return `${this.addCommas(el.lower_bound)} and more`;
          }
        });
        chartRange.repeat_purchase.label = arrayRepeatPurchase.map((el) => {
          if (el.upper_bound) {
            return `${this.addCommas(el.lower_bound)} - ${this.addCommas(
              el.upper_bound
            )}`;
          } else {
            return `${this.addCommas(el.lower_bound)} and more`;
          }
        });
        chartRange.total_spend.label = arrayTotalSpend.map((el) => {
          if (el.upper_bound) {
            return `${this.addCommas(el.lower_bound)} - ${this.addCommas(
              el.upper_bound
            )}`;
          } else {
            return `${this.addCommas(el.lower_bound)} and more`;
          }
        });
        chartRange.recency.data = arrayRecency;
        chartRange.frequency.data = arrayFrequency;
        chartRange.monetary.data = arrayMonetary;
        chartRange.repeat_purchase.data = arrayRepeatPurchase;
        chartRange.total_spend.data = arrayTotalSpend;

        return chartRange;
      } catch (error) {
        console.log(error);
        return chartRange;
      }
    },
    async createDataSets() {
      try {
        const chartRange = await this.getLabel();
        const charts = [
          "Monetary",
          "Frequency",
          "Recency",
          "Repeat_Purchase",
          "total_spend",
        ];
        for (const chart of charts) {
          this.calculateCount(chartRange[chart.toLowerCase()]);
        }

        return chartRange;
      } catch (error) {
        console.log(error);
      }
    },
    loopChartData(variable) {
      var rangeX = 0;
      var label = [];
      for (let index = 0; index <= variable; ) {
        if (variable <= 15) {
          label.push(`${index}`);
          index++;
        } else {
          rangeX += Math.round(variable / 15) + 1;
          label.push(`${index}-${rangeX}`);
          index += Math.round(variable / 15) + 1;
        }
      }
      return label;
    },
    exportFile(params) {
      this.$emit("export", params);
    },
    calculateCount(chartRange) {
      var index = 0;
      const rangeLabel = ["", "min", "avg_min", "avg", "avg_max", "max"];

      for (const data of chartRange.data) {
        chartRange.count.min[index] = 0;
        chartRange.count.avg_min[index] = 0;
        chartRange.count.avg[index] = 0;
        chartRange.count.avg_max[index] = 0;
        chartRange.count.max[index] = 0;
        chartRange.count.sum[index] = 0;
        chartRange.$percent[index] = data.percent;
        chartRange.accumulate.percent[index] = data.percent_accumulate;
        chartRange.accumulate.num[index] = data.accumulate;

        for (const value of data.rfm_sacle_rates) {
          let { rate_scale, num_data, percent_num_data } = value;
          if (chartRange.count[rangeLabel[rate_scale]])
            chartRange.count[rangeLabel[rate_scale]][index] = {
              percent: percent_num_data,
              num_data: num_data,
            };
        }

        index++;
      }
      return chartRange;
    },
    async getChartData() {
      try {
        const dataSets = await this.createDataSets();

        this.chartRange = dataSets;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    returnAvg(key) {
      if (this.RFMData.find((el) => el.name_dashboard == key))
        return this.RFMData.find((el) => el.name_dashboard == key).average;
      return 0;
    },
  },
};
</script>
